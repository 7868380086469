<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="$can('create', 'documento')"
          color="success"
          @click="dialog = true"
          >NOVO</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
        <v-data-table
          :headers="grid.headers"
          :items="filteredItems"
          :loading="grid.loading"
          loading-text="Carregando... aguarde"
          locale="pt"
          class="elevation-1"
        >
          <template v-slot:item.descricao="{ item }">
            <download
              :file="{ path: item.documento, fileName: item.title }"
              :label="item.descricao"
              :uriDownload="urlDownload"
              :user="user.username"
            ></download>
          </template>

          <template v-slot:item.ordem="{ item }">
            <span>{{ item.ordem }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-icon
                v-if="$can('edit', 'documento')"
                color="green"
                class="mr-3"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="$can('delete', 'documento')"
                color="red"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-navigation-drawer
          v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
          v-model="mobileFilter"
          :permanent="$vuetify.breakpoint.lgAndUp"
          :absolute="$vuetify.breakpoint.lgAndUp"
          :fixed="$vuetify.breakpoint.mdAndDown"
          right
          class="elevation-1"
        >
          <v-card elevation="0" class="pa-4">
            <v-card-title class="grey--text text--darken-2"
              >Filtros</v-card-title
            >
            <v-text-field
              v-model="grid.filters.descricao"
              label="Descrição"
              dense
              solo
            ></v-text-field>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="isFormValid"
                autocomplete="off"
                enctype="multipart/form-data"
              >
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Descrição do Arquivo"
                      v-model="defaultUser.descricao"
                      :rules="rules.descricao"
                      required
                      rows="3"
                      counter
                      maxlength="255"
                    >
                    </v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      label="Ordem de exibição"
                      v-model="defaultUser.ordem"
                      :rules="rules.ordem"
                      required
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      v-model="files"
                      counter
                      clearable
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      show-size
                      :disabled="isProcessing"
                      :loading="isProcessing"
                      truncate-length="15"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
            <v-btn color="blue darken-1" text @click="handleItem">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import pageLoadingMixin from "mixins/page-loading.mixin";
import Validations from "services/validations/validations.js";
import notificationMixin from "mixins/notification.mixin";
import configurations from "~/commons/configurations";
import { AUTH_NAMESPACE, USER, MENU } from "store/modules/auth";
import download from "components/download.vue";

const { mapGetters: postLoginGetters } = createNamespacedHelpers(
  AUTH_NAMESPACE
);

export default {
  name: "Registerdocumento",
  mixins: [pageLoadingMixin, notificationMixin],
  beforeMount() {
    this.showPageLoading();
    this.verificarMenuDoUsuario();
  },
  created() {
    this.getdocumentos();
  },
  components: {
    download,
  },
  data: () => ({
    files: [],
    editedIndex: -1,
    indexUser: -1,
    dialog: false,
    isFormValid: undefined,
    isProcessing: false,
    isMobileFilterVisible: false,
    mobileFilter: null,
    breadcrumbs: [
      { text: "Início", to: "/admin" },
      { text: "Documentos", to: "/admin/documentos" },
    ],
    grid: {
      loading: false,
      filtered: false,
      filters: {
        documento: "",
        descricao: "",
      },
      headers: [
        { text: "Id", value: "id", align: " d-none" },
        { text: "Descrição", value: "descricao" },
        { text: "Ordem Exibição", value: "ordem" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [],
    },
    grupos: [],
    cleanUser: {
      id: 0,
      documento: "",
      descricao: "",
      ordem: 0,
      nomeDeUsuario: "",
    },
    defaultUser: {
      id: 0,
      documento: "",
      descricao: "",
      arquivo: [],
      ordem: 0,
      nomeDeUsuario: "",
    },
  }),
  methods: {
    getdocumentos() {
      this.grid.loading = true;
      this.$api()
        .get(configurations.api.admin.documento.getDocumentosUri)
        .then(({ data }) => {
          this.grid.loading = false;
          this.grid.data = data.documento.map((item) => {
            return {
              id: item.id,
              documento: item.documento,
              descricao: item.descricao,
              ordem: item.ordem,
            };
          });
          this.hidePageLoading();
        })
        .catch(({ response }) => {
          if (response && response.descricao) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning(
              "Não conseguimos consultar a lista de documentos."
            );
          }
          this.hidePageLoading();
        });
      this.grid.loading = false;
    },
    adddocumento() {
      const data = this.getFormData();
      this.$api()
        .post(configurations.api.admin.documento.getRegisterDocumentosUri, data)
        .then(({ data }) => {
          if (data && data.documento && data.documento.descricao) {
            this.grid.data.unshift(data.documento);
            this.notifySuccess("Documento cadastrado com sucesso.");
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos cadastrar o documento.");
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      this.indexUser = this.editedIndex;
      this.defaultUser = Object.assign({}, item);
      this.showDialog();
    },
    showDialog() {
      this.dialog = true;
    },
    getFormData() {
      this.defaultUser.nomeDeUsuario = this.user.username;
      let data = new FormData();
      data.append("anexos", this.files);
      data.append("descricao", this.defaultUser.descricao);
      data.append("Id", this.defaultUser.id);
      data.append("documento", this.defaultUser.documento);
      data.append("ordem", this.defaultUser.ordem);
      data.append("nomeDeUsuario", this.defaultUser.nomeDeUsuario);
      return data;
    },
    handleItem() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        if (this.editedIndex > -1) {
          const formDt = this.getFormData();
          this.$api()
            .post(
              configurations.api.admin.documento.getUpdateDocumentosUri,
              formDt
            )
            .then(({ data }) => {
              if (data && data.documentoResposta) {
                Object.assign(
                  this.grid.data[this.indexUser],
                  data.documentoResposta
                );
                this.notifySuccess("Informações atualizadas com sucesso.");
              }
            })
            .catch(({ response }) => {
              if (response && response.data) {
                this.notifyWarning(response.data.mensagem);
              } else {
                this.notifyWarning("Não conseguimos atualizar o documento.");
              }
            });
        } else {
          this.adddocumento();
        }
        this.close();
      } else this.notifyWarning("Preencha todos os campos obrigatórios");
    },
    close() {
      this.dialog = false;
      this.defaultUser = Object.assign({}, this.cleanUser);
      this.editedIndex = -1;
    },
    getArquivo(documento) {
      return `${configurations.arquivos.paginaInicial}${documento}`;
    },
    deleteItem(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      var result = confirm("Tem certeza de que deseja excluir este Documento?");
      if (result) {
        item.nomeDeUsuario = this.user.username;
        this.$api()
          .post(configurations.api.admin.documento.getDeleteDocumentosUri, item)
          .then(({ data }) => {
            if (data && data.sucesso) {
              this.grid.data.splice(this.editedIndex, 1);
              this.notifySuccess("Documento deletado com sucesso.");
            }
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.notifyWarning(response.data.mensagem);
            } else {
              this.notifyWarning("Não conseguimos deletar o Documento.");
            }
          });
      }
    },
    filter() {
      if (this.grid.filters.descricao) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
    verificarMenuDoUsuario() {
      if (this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`].filter(m => m.url === "/admin/documentos").length === 0) {
        this.$router.push({ path: "/login" });
      }
    }
  },
  computed: {
    ...postLoginGetters([USER]),
    filteredItems() {
      return this.grid.data.filter((i) => {
        return i.descricao.includes(this.grid.filters.descricao.toLowerCase());
      });
    },
    rules() {
      return {
        documento: [
          //(value) => Validations.required(value),
          //(value) => Validations.min(value, 1),
          //(value) => Validations.max(value, 300),
        ],
        descricao: [
          //(value) => Validations.required(value),
          //(value) => Validations.min(value, 1),
          (value) => Validations.max(value, 1000),
        ],
        ordem: [(value) => Validations.required(value)],
      };
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Cadastrar documento"
        : "Editar documento";
    },
    urlDownload() {
      return configurations.api.admin.documento.getDownloadDocumentoUri;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}

.shorten {
  max-width: calc(100% - 260px);
}

.hidden {
  visibility: hidden;
  display: none;
}
</style>
